"use client";

import React from "react";
import Link from "next/link";
import { useDownloadStore } from "@/store/use-download.store";
import { QRCodeSVG } from "qrcode.react";

import { Icons } from "@acme/ui/components/icons";
import { Button } from "@acme/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@acme/ui/components/ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@acme/ui/components/ui/drawer";
import { Separator } from "@acme/ui/components/ui/separator";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@acme/ui/components/ui/tabs";

export default function DownloadDrawerSheet() {
  const { showModal, setShowModal } = useDownloadStore();

  const ANDROID_LINK =
    "https://play.google.com/store/apps/details?id=com.taro97.cohub42";
  const IOS_LINK = "#"; // Replace with actual App Store link

  return (
    <Drawer open={showModal} onOpenChange={setShowModal}>
      <DrawerContent className="max-h-[96vh]">
        <div className="mx-auto w-full max-w-lg px-4">
          <DrawerHeader className="text-center">
            <DrawerTitle className="text-2xl font-bold">
              Download CoHub42
            </DrawerTitle>
            <DrawerDescription>
              Scan the QR code or click the download button to get started
            </DrawerDescription>
          </DrawerHeader>

          <Tabs defaultValue="ios" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="ios">
                <Icons.apple className="mr-2 h-5 w-5" />
                iOS
              </TabsTrigger>
              <TabsTrigger value="android">
                <Icons.android className="mr-2 h-5 w-5" />
                Android
              </TabsTrigger>
            </TabsList>

            <TabsContent value="android">
              <PlatformCard
                title="Android Version"
                icon={<Icons.android className="h-12 w-12 text-primary" />}
                qrValue={ANDROID_LINK}
                downloadLink={ANDROID_LINK}
                storeName="Google Play"
              />
            </TabsContent>

            <TabsContent value="ios">
              <PlatformCard
                title="iOS Version"
                icon={<Icons.apple className="h-12 w-12 text-primary" />}
                qrValue={IOS_LINK}
                downloadLink={IOS_LINK}
                storeName="App Store"
              />
            </TabsContent>
          </Tabs>

          <DrawerFooter>
            <DrawerClose asChild>
              <Button variant="outline">Close</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

interface PlatformCardProps {
  title: string;
  icon: React.ReactNode;
  qrValue: string;
  downloadLink: string;
  storeName: string;
}

function PlatformCard({
  title,
  icon,
  qrValue,
  downloadLink,
  storeName,
}: PlatformCardProps) {
  return (
    <Card className="mt-4 border-2">
      <CardHeader className="flex flex-row items-center gap-4">
        {icon}
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>Available on {storeName}</CardDescription>
        </div>
      </CardHeader>
      <Separator className="mb-6" />
      <CardContent className="flex flex-col items-center gap-6">
        <div className="rounded-xl bg-white p-4">
          <QRCodeSVG
            value={qrValue}
            size={200}
            level="H"
            includeMargin
            imageSettings={{
              src: "/logo.png",
              height: 40,
              width: 40,
              excavate: true,
            }}
          />
        </div>
        <Link href={downloadLink} target="_blank" className="w-full">
          <Button className="w-full gap-2" size="lg">
            <Icons.download className="h-5 w-5" />
            Download Now
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
}
